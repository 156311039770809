import { graphql } from "gatsby";
import React from "react";
import NewsCard from "../../components/news/newsCard";
import Meta from "../../components/seo/Meta";
import Container from "../../layout/container";

const Blog = ({ data: { strapi } }) => {
  const metaData = {
    title: "Harnessの最新情報｜Harness正規販売代理店 DXable",
    url: "https://harness.dxable.com/news",
  };
  const { news } = strapi;
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <div className="py-0 hn-container">
          <div className="new">
            <div className="new__heading">
              <h1 className="new__heading__main">Harness News</h1>
            </div>
            <div className="new__body">
              <div className="new__body__other-news">
                {news?.data?.map((itm) => (
                  <NewsCard news={itm} key={itm.id} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export const blogPostsQuery = graphql`
  query NEWS_QUERY {
    strapi {
      news(pagination: { limit: 100 }, sort: "releaseDate:desc") {
        data {
          attributes {
            releaseDate
            content
            slug
            seo {
              description
              id
              title
            }
            title
          }
          id
        }
      }
    }
  }
`;

export default Blog;
