import { Link } from "gatsby";
import React from "react";
import moment from "moment";
import "moment/locale/ja";
const NewsCard = ({ news }) => {
  moment.locale("ja");
  return (
    <div className="news-card">
      <Link to={`/news/${news?.attributes?.slug}`}>
        <h2 className="news-card__title">{news?.attributes?.title}</h2>
        <div className="news-card__summary">
          {news?.attributes?.seo.description}
        </div>
        <p className="news-card__date">
          {moment(news?.attributes?.releaseDate).format("LL")}
        </p>
      </Link>
    </div>
  );
};

export default NewsCard;
